import { Link } from "gatsby";
import React from "react";
import Icon from "./icon";
import { cn } from "../lib/helpers";

import * as styles from "./countdown.module.css";

const Countdown = () => {
  let days;
  let hours;
  let minutes;
  let seconds;

  const calculate = dateEnd => {
    var dateStart = new Date();
    var dateStart = new Date(
      dateStart.getUTCFullYear(),
      dateStart.getUTCMonth(),
      dateStart.getUTCDate(),
      dateStart.getUTCHours(),
      dateStart.getUTCMinutes(),
      dateStart.getUTCSeconds()
    );
    var timeRemaining = parseInt((dateEnd - dateStart.getTime()) / 1000);

    if (
      timeRemaining >= 0 &&
      document.getElementById("days") &&
      document.getElementById("hours") &&
      document.getElementById("minutes") &&
      document.getElementById("seconds") &&
      !(typeof window === "undefined" || !window.document)
    ) {
      days = parseInt(timeRemaining / 86400);
      timeRemaining = timeRemaining % 86400;
      hours = parseInt(timeRemaining / 3600);
      timeRemaining = timeRemaining % 3600;
      minutes = parseInt(timeRemaining / 60);
      timeRemaining = timeRemaining % 60;
      seconds = parseInt(timeRemaining);

      document.getElementById("days").innerHTML = parseInt(days, 10);
      document.getElementById("hours").innerHTML = ("0" + hours).slice(-2);
      document.getElementById("minutes").innerHTML = ("0" + minutes).slice(-2);
      document.getElementById("seconds").innerHTML = ("0" + seconds).slice(-2);
    } else {
      return;
    }
  };

  const countdownFn = dateEnd => {
    let timer;

    dateEnd = new Date(dateEnd);
    dateEnd = dateEnd.getTime();

    if (isNaN(dateEnd)) {
      return;
    }

    timer = setInterval(() => calculate(dateEnd), 1000);
  };

  countdownFn("05/26/2023 04:00:00 PM");

  return (
    <div className={styles.root}>
      <div className={styles.timeContainer}>
        <div>dnů</div>
        <div className={styles.time} id="days">
          –
        </div>
      </div>
      <div className={styles.timeContainer}>
        <div>hodin</div>
        <div className={styles.time} id="hours">
          –
        </div>
      </div>
      <div className={styles.timeContainer}>
        <div>minut</div>
        <div className={styles.time} id="minutes">
          –
        </div>
      </div>
      <div className={styles.timeContainer}>
        <div>sekund</div>
        <div className={styles.time} id="seconds">
          –
        </div>
      </div>
    </div>
  );
};

export default Countdown;
