import { Link } from "gatsby";
import React from "react";
import Icon from "./icon";
import { cn } from "../lib/helpers";
import lineup1 from "../images/lineup1.svg"
import lineup2 from "../images/lineup2.svg"


import * as styles from "./lineup.module.css";

const Lineup = () => {
  return (
    <div id="lineup" className={styles.container}>
      <div className={styles.root}>
        <img
          className={styles.lineup1}
          src={lineup1}
        />
        <img
          className={styles.lineup2}
          src={lineup2}
        />
      </div>
    </div>
  );
};

export default Lineup;
