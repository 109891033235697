import React from "react";
import { graphql } from "gatsby";
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture
} from "../lib/helpers";
import Container from "../components/container";
import GraphQLErrorList from "../components/graphql-error-list";
import ProjectPreviewGrid from "../components/project-preview-grid";
import SEO from "../components/seo";
import LandingPage from "../components/landing-page";
import Interprets from "../components/interprets";
import Video from "../components/video";
import Navbar from "../components/navbar";
import Info from "../components/info";
import Acomodation from "../components/acomodation";
import Transport from "../components/transport";
import Media from "../components/media";
import Contact from "../components/contact";
import Layout from "../containers/layout";
import Map from "../components/map";
import Lineup from "../components/lineup";
import Partners from "../components/partners";

export const query = graphql`
  query IndexPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
  }
`;

const IndexPage = props => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  return (
    <Layout>
      <SEO title={site.title} description={site.description} keywords={site.keywords} />
      <LandingPage />
      <Navbar />
      <Interprets />
      <Lineup />
      {/* <Map /> */}

      <Video />
      <Info />
      {/* <Acomodation /> */}
      {/* <Transport /> */}
      {/* <Media /> */}
      <Contact />
      <Partners />
    </Layout>
  );
};

export default IndexPage;
