import React from "react";
import { cn } from "../lib/helpers";

import * as styles from "./info.module.css";

const Info = () => (
  <div id="info" className={styles.root}>
    <svg xmlns="http://www.w3.org/2000/svg" width="110" data-name="001-tickets">
      <g data-name="Group 10">
        <g fill="#373636" data-name="Group 9">
          <circle
            cx="4.314"
            cy="4.314"
            r="4.314"
            data-name="Ellipse 1"
            transform="translate(73.767 84.688)"
          />
          <circle
            cx="4.314"
            cy="4.314"
            r="4.314"
            data-name="Ellipse 2"
            transform="translate(63.844 29.038)"
          />
          <path
            d="M106.122 67.432h4.314V41.549h-11a8.627 8.627 0 0 1-3.975-16.284l3.674-1.914L88.149 0 .101 41.53l.009.019H0v25.883h4.314a8.628 8.628 0 1 1 0 17.256H0v25.883h110.435V84.688h-4.314a8.628 8.628 0 1 1 0-17.255Zm-47.418-44a4.309 4.309 0 0 0 7.793-3.676l17.522-8.265 4 8.495a17.236 17.236 0 0 0-3.514 21.567H20.286Zm43.1 69.341v9.174H82.395a4.314 4.314 0 0 0-8.628 0H8.628V92.77a17.258 17.258 0 0 0 0-33.419v-9.174h65.14a4.314 4.314 0 0 0 8.628 0h19.412v9.173a17.258 17.258 0 0 0 0 33.419Z"
            data-name="Path 7"
          />
          <circle
            cx="4.314"
            cy="4.314"
            r="4.314"
            data-name="Ellipse 3"
            transform="translate(73.767 58.805)"
          />
          <circle
            cx="4.314"
            cy="4.314"
            r="4.314"
            data-name="Ellipse 4"
            transform="translate(73.767 71.746)"
          />
        </g>
      </g>
    </svg>
    <h1 className={styles.headline}>Vstupenky</h1>
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.box}></div>
        <div className={styles.box}>
          <div>Jednodenní</div>
        </div>
        <div className={styles.box}>
          <div>Dvoudenní</div>
        </div>
        <div className={styles.box}>
          <div>Afterparty</div>
        </div>
        <div className={styles.box}>Předprodej</div>
        <div className={styles.box}>350 Kč</div>
        <div className={styles.box}>500 Kč</div>
        <div className={styles.box}>150 Kč</div>
        <div className={styles.box}>Na místě</div>
        <div className={styles.box}>450 Kč</div>
        <div className={styles.box}>700 Kč</div>
        <div className={styles.box}>200 Kč</div>
        <div className={styles.box}>VIP</div>
        <div className={styles.box}></div>
        <div className={styles.box}>5800 Kč</div>
        <div className={styles.box}></div>
      </div>
    </div>
    <h1 className={styles.headline}>Prodejní místa</h1>
    <div className={cn(styles.container, styles.salesContainer)}>
        <div className={styles.box}>
          <div>Srdcovka Monika</div>
          <div className={styles.address}>Kosmonautů 4554, Chomutov</div>
        </div>
        <div className={styles.box}>
          <div>Klub DOLE</div>
          <div className={styles.address}>Kosmonautů 4554, Chomutov</div>
        </div>
        <div className={styles.box}>
          <div>Kino Jirkov</div>
          <div className={styles.address}>Rooseveltova 407, Jirkov</div>
        </div>
        <div className={styles.box}>
          <div>Lemon Café</div>
          <div className={styles.address}>Obchodní zóna 307, Otvice</div>
        </div>
        <div className={styles.box}>
          <div>Studio 4tan</div>
          <div className={styles.address}>Rooseveltova 2431/25, Chomutov</div>
        </div>
        <div className={styles.box}>
          <div>Grill & Coffee</div>
          <div className={styles.address}>Raisova 5456, Chomutov</div>
        </div>
    </div>
  </div>
);

export default Info;
