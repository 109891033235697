// extracted by mini-css-extract-plugin
export var box = "interprets-module--box--16ead";
export var container = "interprets-module--container--a5f5d";
export var container2 = "interprets-module--container2--75920";
export var image = "interprets-module--image--460b1";
export var interpret = "interprets-module--interpret--f02db";
export var root = "interprets-module--root--41829";
export var triangle1 = "interprets-module--triangle1--a869f";
export var triangle2 = "interprets-module--triangle2--ccc11";
export var triangle3 = "interprets-module--triangle3--e1e25";
export var triangle4 = "interprets-module--triangle4--4c2b5";
export var upper = "interprets-module--upper--766af";