import { Link } from "gatsby";
import React from "react";
import Icon from "./icon";
import { cn } from "../lib/helpers";
import partneri1 from "../images/partneri1.svg"
import partneri2 from "../images/partneri2.svg"
import Chomutov from "../lib/chomutov_logo.png"

import * as styles from "./partners.module.css";

const Partners = () => {
  return (
    <div id="partners" className={styles.root}>
      <img
        className={styles.partneri1}
        src={partneri1}
      />
      <img
        className={styles.partneri2}
        src={partneri2}
      />
    </div>
  );
};

export default Partners;
