import { Link } from "gatsby";
import React from "react";
import Icon from "./icon";
import { cn } from "../lib/helpers";
import Chinaski from "../lib/chinaski_web.png";
import Ewa from "../lib/farna_web.png";
import Jelen from "../lib/jelen_web.png";
import Adonxs from "../lib/adonxs_web.png";

import * as styles from "./interprets.module.css";

const Interprets = () => {
  return (
    <div id="program" className={styles.root}>
      <div className={styles.container}>
        <div className={styles.box}>
          <div className={styles.triangle1} />
          <img className={styles.image} src={Chinaski} />
          <div className={styles.interpret}>
            <div>Chinaski</div>
          </div>
        </div>
        <div className={styles.box}>
          <div className={styles.triangle2} />
          <img className={styles.image} src={Ewa} />
          <div className={styles.interpret}>
            <div>Ewa Farna</div>
          </div>
        </div>
        <div className={styles.box}>
          <div className={styles.triangle3} />
          <img className={styles.image} src={Jelen} />
          <div className={styles.interpret}>
            <div>Jelen</div>
          </div>
        </div>
        <div className={styles.box}>
          <div className={styles.triangle4} />
          <img className={styles.image} src={Adonxs} />
          <div className={styles.interpret}>
            <div>Adonxs</div>
            <div className={styles.upper}>Adam Pavlovčin</div>
          </div>
        </div>
      </div>
      <div className={styles.container2}>
        <div className={styles.box}>
          <div style={{marginBottom: '-14px'}}>Smokers</div>
          <div className={styles.upper}>Spirit of Chris Norman</div>
        </div>
        <div className={styles.box}>
          Lucie Revival
        </div>
        <div className={styles.box}>
          A další...
        </div>
      </div>
    </div>
  );
};

export default Interprets;
