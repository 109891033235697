// extracted by mini-css-extract-plugin
export var bl = "landing-page-module--bl--de4c3";
export var br = "landing-page-module--br--4ef29";
export var container = "landing-page-module--container--3a9d6";
export var countdown = "landing-page-module--countdown--14b27";
export var countdownContainer = "landing-page-module--countdownContainer--b9b78";
export var countdownTitle = "landing-page-module--countdownTitle--ee5b9";
export var cta = "landing-page-module--cta--64e5e";
export var root = "landing-page-module--root--b7d8d";
export var tl = "landing-page-module--tl--5963c";
export var tr = "landing-page-module--tr--11c78";
export var triangle1 = "landing-page-module--triangle1--2f613";
export var triangle2 = "landing-page-module--triangle2--a156c";
export var triangle3 = "landing-page-module--triangle3--87ead";