import { Link } from "gatsby";
import React from "react";
import Icon from "./icon";
import { cn } from "../lib/helpers";
import Countdown from "./countdown";

import * as styles from "./landing-page.module.css";

const LandingPage = () => {
  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <div className={styles.triangle1} />
        <div className={styles.triangle2} />
        <div className={styles.triangle3} />
        <div className={styles.tl}>
          <div>Kvě</div>
          <div>ten</div>
          <div>les</div>
        </div>
        <div className={styles.tr}>
          <a href="https://www.instagram.com/kvetenles/" target="_blank">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 31.518 31.511"
            >
              <path
                id="Icon_awesome-instagram"
                data-name="Icon awesome-instagram"
                d="M15.757,9.914a8.079,8.079,0,1,0,8.079,8.079A8.066,8.066,0,0,0,15.757,9.914Zm0,13.331a5.252,5.252,0,1,1,5.252-5.252,5.262,5.262,0,0,1-5.252,5.252ZM26.051,9.584A1.884,1.884,0,1,1,24.166,7.7,1.88,1.88,0,0,1,26.051,9.584ZM31.4,11.5a9.325,9.325,0,0,0-2.545-6.6,9.387,9.387,0,0,0-6.6-2.545c-2.6-.148-10.4-.148-13,0a9.373,9.373,0,0,0-6.6,2.538,9.356,9.356,0,0,0-2.545,6.6c-.148,2.6-.148,10.4,0,13a9.325,9.325,0,0,0,2.545,6.6,9.4,9.4,0,0,0,6.6,2.545c2.6.148,10.4.148,13,0a9.325,9.325,0,0,0,6.6-2.545,9.387,9.387,0,0,0,2.545-6.6c.148-2.6.148-10.392,0-12.994ZM28.041,27.281a5.318,5.318,0,0,1-3,3c-2.074.823-7,.633-9.288.633s-7.221.183-9.288-.633a5.318,5.318,0,0,1-3-3c-.823-2.074-.633-7-.633-9.288s-.183-7.221.633-9.288a5.318,5.318,0,0,1,3-3c2.074-.823,7-.633,9.288-.633s7.221-.183,9.288.633a5.318,5.318,0,0,1,3,3c.823,2.074.633,7,.633,9.288S28.863,25.214,28.041,27.281Z"
                transform="translate(0.005 -2.238)"
                fill="#3e4046"
              />
            </svg>
          </a>
          <a href="https://www.facebook.com/kvetenles/" target="_blank">
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="22" viewBox="0 0 19.281 36">
              <path
                id="Icon_awesome-facebook-f"
                data-name="Icon awesome-facebook-f"
                d="M19.627,20.25l1-6.515H14.375V9.507c0-1.782.873-3.52,3.673-3.52h2.842V.44A34.658,34.658,0,0,0,15.846,0C10.7,0,7.332,3.12,7.332,8.769v4.965H1.609V20.25H7.332V36h7.043V20.25Z"
                transform="translate(-1.609)"
                fill="#3e4046"
              />
            </svg>
          </a>
        </div>
        <div className={styles.bl}>
          <div>stadion</div>
          <div>u městského parku</div>
          <div>chomutov</div>
        </div>
        <div className={styles.br}>
          <div>26–27</div>
          <div>/ 05</div>
        </div>

        <div className={styles.countdownContainer}>
          <div className={styles.countdown}>
            <div className={styles.countdownTitle}>Největší dvoudenní festival</div>
            <div className={styles.countdownTitle}>v Chomutově již za</div>
            <Countdown />
            <a
              className={styles.cta}
              href="https://kvetenles-festival.enigoo.cz/app/tickets/events/8"
              target="_blank"
            >
              <div>Chci jít</div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
