import { Link } from "gatsby";
import React from "react";
import { cn } from "../lib/helpers";

import * as styles from "./contact.module.css";

const Info = () => (
  <div id="kontakt" className={styles.root}>
    <div className={styles.container}>
      <div className={styles.contact}>
        <div className={styles.triangle1} />
        <div className={styles.name}>Simona Šlapáková</div>
        <div className={styles.position}>Projektová manažerka</div>
        <div>simona@kvetenles-festival.cz</div>
        <div>605 484 528</div>
      </div>
      <div className={styles.contact}>
        <div className={styles.triangle2} />
        <div className={styles.name}>Alexandr Vostrý</div>
        <div className={styles.position}>Produkční manažer</div>
        <div>alexandr@kvetenles-festival.cz</div>
        <div>607 769 471</div>
      </div>
    </div>
    <div className={styles.container}>
      <div className={styles.contact}>
        <div className={styles.name}>ArtCulture s.r.o.</div>
        <div className={styles.position}>Kořenského 90/4, 400 03 Ústí nad Labem – Střekov</div>
        <div>IČ: 07855206</div>
        <div>DIČ: CZ07855206</div>
        <div>Spisová značka: C 43116 vedená u Krajského soudu v Ústí nad Labem</div>
      </div>
    </div>
  </div>
);

export default Info;
