import { Link } from "gatsby";
import React from "react";
import { cn } from "../lib/helpers";

import * as styles from "./navbar.module.css";

const Navbar = () => (
  <div className={styles.root}>
    <div className={styles.logo}>
      <div>Květenles</div>
    </div>
    <nav className={styles.nav}>
      <a href="#program">Interpreti</a>
      <a href="#lineup">Line-up</a>
      <a href="#info">Info</a>
      <a href="#kontakt">Kontakt</a>
      {/* <Link to="/navstevni-rad/">Návštěvní řád</Link> */}
    </nav>
    <a
      className={styles.cta}
      href="https://kvetenles-festival.enigoo.cz/app/tickets/events/8"
      target="_blank"
    >
      <div>vstupenky</div>
    </a>
  </div>
);

export default Navbar;
