import { Link } from "gatsby";
import React from "react";
import Icon from "./icon";
import { cn } from "../lib/helpers";

import * as styles from "./video.module.css";

const Video = () => {
  return (
    <div className={styles.root}>
      <iframe
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/cYxqtEADRak"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default Video;
